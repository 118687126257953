
export default defineNuxtPlugin(() => {

  const { CDN_URL } = useRuntimeConfig().public

  const fetchProject = async (args) => {

    const projectState = useState('project')


    if (process.client) {


      if (typeof (args) == 'object') {

        let { address, token } = args;

        if (!address || !token) {
          address = '0x0'
          token = '0x0'
        }

        const url = `${CDN_URL}/projects?address=${address}`
        let result = await $fetch(url, {
          method: 'GET',
          redirect: 'follow',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).catch(err => {
          console.error(err)
          projectState.value = null
        })

        // replace project_logo, and project_banner.
        // split to only get the filename.ext

        //console.log(result)

        result = result?.map((p) => {
          return {
            //...p,
            project_id: p.project_id,
            project_name: p.name,
            project_logo: p.logo,
            project_banner: p.banner,
            project_description: p.description,
            project_ath: p.all_time_high,
            project_tier_allocation: p.tier_allocations,
            is_ushi_private: p.is_ushi_private,
            project_status: p.status,
            project_logo: p.logo?.split('/')?.pop(),
            project_banner: p.banner?.split('/')?.pop(),
            project_tags: p.tags,
            project_index: p.index,
            project_roi: p.roi,
          }
        })

        let categories = ['RECENT', 'COMPLETED', 'FEATURED', 'UPCOMING', 'ACTIVE']
        let projectByCategories = {}

        categories?.forEach((c) => {
          projectByCategories[c.toLowerCase()] = {
            'projects': result?.filter((p) => {
              if (c === "COMPLETED") {
                if (p.project_status === 'FEATURED' || p.project_status === 'COMPLETED') {
                  return p.project_index >= 27
                } else return false
              } else if (c === "RECENT") {
                return p.project_tags?.includes(c)
              } else {
                return p.project_status === c
              }
            }).sort((a, b) => {
              if (c === 'FEATURED') {
                return b.project_roi - a.project_roi
              } else {
                return b.project_index - a.project_index
              }
            })
          }
        })

        // console.log(projectByCategories)
        projectState.value = projectByCategories

      }

    } else {
      // console.log("fetch project")


      let current_address = useCookie('current_address', {
        readonly: true
      })
      let current_token = useCookie(`token`, {
        readonly: true
      })

      // console.log("app middleware", current_address.value, current_token.value)

      if (!current_address?.value || !current_token?.value) {
        current_address.value = '0x0'
        current_token.value = '0x0'
      }

      let result = await $fetch(`${CDN_URL}/projects?address=${current_address.value}`, {
        method: 'GET',
        redirect: 'follow',
        headers: {
          'Authorization': `Bearer ${current_token.value}`
        }
      }).catch(err => {
        console.error(err)
        projectState.value = null
      })

      // replace project_logo, and project_banner.
      // split to only get the filename.ext

      result = result?.map((p) => {
        return {
          project_id: p.project_id,
          project_name: p.name,
          project_logo: p.logo,
          project_banner: p.banner,
          project_description: p.description,
          project_ath: p.all_time_high,
          project_tier_allocation: p.tier_allocations,
          is_ushi_private: p.is_ushi_private,
          project_status: p.status,
          project_logo: p.logo.split('/').pop(),
          project_banner: p.banner.split('/').pop(),
          project_tags: p.tags,
          project_index: p.index,
          project_roi: p.roi,
        }
      })

      let categories = result?.map((p) => p.project_status)
      let projectByCategories = {}

      categories?.forEach((c) => {
        projectByCategories[String(c).toLowerCase()] = {
          'projects': result?.filter((p) => p.project_status === c)
        }
      })


      projectState.value = projectByCategories
    }
  }

  const fetchUser = async (args) => {
    // console.log(CDN_URL)

    const userState = useState('user')


    if (process.client) {

      // console.log(args)

      if (typeof (args) == 'object') {

        let token, address

        address = args?.address
        token = args?.token


        if (address && token) {

          let result = await $fetch(`${CDN_URL}/users/${address}`, {
            method: 'GET',
            redirect: 'follow',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })

          // console.log("fetch user", result)

          userState.value = {
            ...result,
            user_address: address
          }



        } else {

          userState.value = false
        }

      }



    } else {
      let token, address

      address = useCookie('current_address', {
        readonly: true
      }).value
      token = useCookie(`${address}_token`, {
        readonly: true
      }).value

      // console.log("USDER", address, token?.substring(0, 10))


      // let current_address = useCookie('current_address')
      // let current_token = useCookie(`${current_address.value}_token`)

      // console.log("fetch user", current_address.value, current_token.value)


      if (address && token && (address != '0x0')) {

        try {
          let result = await $fetch(`${CDN_URL}/users/${address}`, {
            method: 'GET',
            redirect: 'follow',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })

          // console.log("fetch user", result)

          userState.value = {
            ...result,
            user_address: address
          }
        } catch (err) {
          userState.value = false
        }
      } else {

        // console.log("FALSY", address, token?.substring(0, 10))

        userState.value = false
      }

    }


    // console.log("USERSTATE", userState.value)


  }

  const fetchAnn = async () => {

    const annState = useState('announcements')

    try {
      let result = await $fetch(`${CDN_URL}/announcements`, {
        method: 'GET',
        redirect: 'follow',
      })

      // console.log(result)



      annState.value = result.map(res => {
        return {
          title: res.title,
          // banner: res.banner,
          cdn_banner: res.cdn_banner.replace('https://oxbull-ann.s3-ap-southeast-1.amazonaws.com/', '/ann/'),
          type: res.type,
          config: {
            dataTweetUrl: res.data_tweet_url,
          },
        }
      })
    } catch (err) {
      // console.log(err)
    }

  }

  const fetchVesting = async (args) => {

    const _fetchVesting =  (args) => {
      return new Promise( async (resolve, reject) => {

        let { address, token } = args;

        try {
          let result = await $fetch(`${CDN_URL}/users/${address}/vestings`, {
            method: "GET",
            redirect: "follow",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          resolve(result)
      
      
        } catch (err) {
          console.log(err);
  
          // vestingsState = ref(null);
          resolve(null)
  
        }


      })
    }

    let vestingState = useState('vesting')

    if(process.client) {
      console.log("fetch vesting")

      if (typeof (args) == 'object') {

        let token, address

        address = args?.address
        token = args?.token

        if(address && token) {
          let result = await _fetchVesting({address, token})
          vestingState.value = ref(result)
        }else{
          vestingState = ref(null)
        }

      }
    }else{

      let address, token

      address = useCookie("current_address", {
        readonly: true,
      }).value;
    
      token = useCookie(`${address}_token`, {
        readonly: true,
      }).value;

      if (address && token && (address != '0x0')) {
        let result = await _fetchVesting({address, token})
        vestingState.value = ref(result)
      }else{
        vestingState = ref(null)
      }
    
      
    
      

    }

  }

  const fetchHome = async () => {

    //if(process.client) {
      console.log("fetch home")

      try {
        let result = await $fetch('/api/home',{
          method: 'GET',
          redirect: 'follow'
        })

        let projectState = useState('project')
        let userState = useState('user')
        let annState = useState('announcements')

        projectState.value = result.project
        userState.value = result.user
        annState.value = result.ann

      } catch (err) {
        console.error(err)
      }
      


    //} else {

    //  await Promise.all([fetchProject(), fetchUser(), fetchAnn()]);

    //}
//
  }

  return {
    provide: {
      fetchProject,
      fetchUser,
      fetchAnn,
      fetchVesting,
      fetchHome
    }
  }

})
