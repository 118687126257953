import Tracker from '@openreplay/tracker';


export default defineNuxtPlugin((nuxtApp) => {

    const tracker = new Tracker({
        userIdEnabled: true,
        projectKey: "TVXcsTngbm8pT1C21v3E"
    });

    const init = () => {
        tracker.start();
    }

    init();



    return {
        provide: {
            tracker: tracker
        }
    }

})
