import revive_payload_client_8HBWKGiWWS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@2.79.1_sass@1.77.8_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_iWHoMpf1rp from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@2.79.1_sass@1.77.8_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_dUSOiaH2Uq from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@2.79.1_sass@1.77.8_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_PFQau9LZZn from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@2.79.1_sass@1.77.8_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_YHeUPRfeN2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@2.79.1_sass@1.77.8_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_h3A4r6SQIn from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@2.79.1_sass@1.77.8_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_DzIOSgjmR6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@2.79.1_sass@1.77.8_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_99v7aHwrf6 from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.3_rollup@2.79.1_typescript@5.5.4_vue@3.4.37/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_JJg3mYr5ab from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@2.79.1_sass@1.77.8_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_bUO2JiGjKe from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@2.79.1_vite@5.4.0_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_gVPXxungPq from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_rollup@2.79.1_vite@5.4.0_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import nuxt_plugin_4fhlLDz02e from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-delay-hydration@1.3.6_rollup@2.79.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import slideovers_ZXsV48ueRU from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_qrcode@1.5.4_rollup@2.79.1_vite@5.4.0_vue@3.4.37/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_xAHy0ig5Yt from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_qrcode@1.5.4_rollup@2.79.1_vite@5.4.0_vue@3.4.37/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_qBhcJPncu5 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_qrcode@1.5.4_rollup@2.79.1_vite@5.4.0_vue@3.4.37/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_FUzMblK75w from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_rollup@2.79.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_BBTCEV1WBJ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.4.5_rollup@2.79.1_vite@5.4.0_vue@3.4.37/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import floating_vue_EIcJ7xiw0h from "/opt/buildhome/repo/.nuxt/floating-vue.mjs";
import plugin_client_1VPjJSqH15 from "/opt/buildhome/repo/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_rollup@2.79.1_vue@3.4.37/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_wofILYXtuU from "/opt/buildhome/repo/node_modules/.pnpm/@hypernym+nuxt-anime@2.1.1/node_modules/@hypernym/nuxt-anime/dist/runtime/plugin.mjs";
import plugin_qrFZLrGP7b from "/opt/buildhome/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.3_pinia@2.2.1_rollup@2.79.1/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import calendar_client_YJGsTbn2VI from "/opt/buildhome/repo/plugins/calendar.client.js";
import content_GArnI6Y6ho from "/opt/buildhome/repo/plugins/content.js";
import tracker_client_GsGCjPbvL8 from "/opt/buildhome/repo/plugins/tracker.client.js";
import vconsole_client_Zu3Hk7iamm from "/opt/buildhome/repo/plugins/vconsole.client.js";
import wallet_client_dYVbaY1ZcL from "/opt/buildhome/repo/plugins/wallet.client.js";
export default [
  revive_payload_client_8HBWKGiWWS,
  unhead_iWHoMpf1rp,
  router_dUSOiaH2Uq,
  payload_client_PFQau9LZZn,
  navigation_repaint_client_YHeUPRfeN2,
  check_outdated_build_client_h3A4r6SQIn,
  chunk_reload_client_DzIOSgjmR6,
  plugin_vue3_99v7aHwrf6,
  components_plugin_KR1HBZs4kY,
  prefetch_client_JJg3mYr5ab,
  pwa_icons_bUO2JiGjKe,
  pwa_client_gVPXxungPq,
  nuxt_plugin_4fhlLDz02e,
  slideovers_ZXsV48ueRU,
  modals_xAHy0ig5Yt,
  colors_qBhcJPncu5,
  plugin_client_FUzMblK75w,
  plugin_BBTCEV1WBJ,
  floating_vue_EIcJ7xiw0h,
  plugin_client_1VPjJSqH15,
  plugin_wofILYXtuU,
  plugin_qrFZLrGP7b,
  calendar_client_YJGsTbn2VI,
  content_GArnI6Y6ho,
  tracker_client_GsGCjPbvL8,
  vconsole_client_Zu3Hk7iamm,
  wallet_client_dYVbaY1ZcL
]