<script setup>
import { useUserStore } from "~/stores/user.js";
const userStore = useUserStore();

onMounted(() => {
  let setArrowLabel = setInterval(() => {
    // button.vc-arrow selector
    // multiple arrows

    let arrows = document.querySelectorAll(".vc-arrow");

    if (arrows.length > 0) {
      arrows.forEach((arrow) => {
        arrow.setAttribute("aria-label", "Calendar Arrow");
      });

      clearInterval(setArrowLabel);
    }
  }, 200);

  let wcmModalLabel = setInterval(() => {
    let modal = document.querySelector("wcm-modal");

    if (modal) {

      let modalRoot = modal && modal.shadowRoot;

      modalRoot.querySelector("#wcm-modal").setAttribute("aria-label", "WalletConnect Modal");

      // modal.setAttribute('aria-label', 'Modal')
      clearInterval(wcmModalLabel);
    }
  }, 500);
});

// onMounted( () => {
//   $init()

//   // watch($userData, () => {
//   //       // console.log("userData changed", $userData.value)
//   //       $renderCalendar()
//   //   })

//     watch($accessToken, () => {
//         // console.log("AT")
//         $renderCalendar()
//         // $fetchUser({
//         //   token: useCookie(`${$address.value}_token`).value,
//         //   address: $address.value
//         // })
//     })

//     $renderCalendar()

// })

// onMounted(() => {
//   const { $startTracking } = useNuxtApp()
//   tracker = $startTracking()

//   userStore.reauth();

// })

// const unwatch = watchAccount(config, {
//   onChange(next, prev) {

//     if (prev.address !== next.address)
//       userStore.reauth();

//     tracker.setUserID(next.address);
//   },
// });

// onUnmounted(() => {
//   unwatch();
// });

// onMounted(() => {

// });

// const cancelSign = () => {
//   userStore.signing = false;
// };

const pauseUI = computed(() => {
  return userStore.pauseUI || userStore.signing || userStore.loading;
});
</script>

<template>
  <div :class="[pauseUI ? 'w-screen h-screen bg-gradient-to-t from-woodsmoke-900 to-turquoise-900 ' : 'w-full h-full ', 'flex justify-center items-center ']">

    <div :class="[pauseUI ? 'scale-75 sm:w-full sm:h-full w-[99vw] h-[99vw] sm:mt-0 mt-[-25%] origin-center rounded-[3rem] overflow-hidden shadow-2xl duration-[0.6s] ease-[cubic-bezier(0.85, 0, 0.15, 1)]' : 'duration-[0.7s] ease-[cubic-bezier(0.22, 1, 0.36, 1)] origin-top w-full h-full']" class="  ">
      <NuxtLayout>
        <NuxtPage></NuxtPage>
      </NuxtLayout>

      <div class="bg-white/20 backdrop-blur-xl w-screen h-screen absolute top-0 left-0 z-[999] flex flex-col justify-center items-center" v-if="pauseUI"></div>
    </div>

    <div v-if="userStore.pauseUI" class="w-screen h-screen absolute flex justify-center items-center flex-col">
      <div class="flex justify-center items-center -mt-8 sm:mt-0">
        <Icon name="gridicons:pause" class="text-[4rem] text-white"></Icon>
        <Icon name="line-md:loading-loop" class="text-[8rem] text-white/50 absolute"></Icon>
      </div>

      <div class="text-white sm:mt-20 sm:-mb-20 flex flex-col justify-center items-center mt-8">
        <div class="p-1 px-4 bg-turquoise-500 text-turquoise-1000 rounded-md shadow-xl shadow-black/30 mb-4">
          <p>Pending Transaction</p>
        </div>
        <p class="text-xl text-center px-20">Complete Transaction in your Wallet to Continue</p>
      </div>
    </div>

    <div v-if="userStore.loading" class="w-screen h-screen absolute flex justify-center items-center flex-col">
      <div class="flex justify-center items-center -mt-8 sm:mt-0">
        <Icon name="gridicons:pause" class="text-[4rem] text-white"></Icon>
        <Icon name="line-md:loading-loop" class="text-[8rem] text-white/50 absolute"></Icon>
      </div>

      <div class="text-white sm:mt-20 sm:-mb-20 flex flex-col justify-center items-center mt-8">
        <div class="p-1 px-4 bg-turquoise-500 text-turquoise-1000 rounded-md shadow-xl shadow-black/30 mb-4">
          <p>Loading</p>
        </div>
      </div>
    </div>

    <div v-if="userStore.signing" class="w-screen h-screen absolute flex justify-center items-center flex-col">
      <div class="flex justify-center items-center -mt-12 sm:mt-0">
        <Icon name="mdi:sign" class="text-[4rem] text-white"></Icon>
        <Icon name="line-md:loading-loop" class="text-[8rem] text-white/50 absolute"></Icon>
      </div>

      <div class="text-white sm:mt-20 sm:-mb-20 flex flex-col justify-center items-center mt-8">
        <div class="p-1 px-4 bg-turquoise-500 text-turquoise-1000 rounded-md shadow-xl shadow-black/30 mb-4">
          <p>Signature Requested</p>
        </div>
        <p class="sm:text-xl text-base text-center px-20">Signing ensures only you access your information, and it's free.</p>

        <button data-no-blobity @click="cancelSign()" class="btn btn-link text-red-500 mt-4 text-lg">Cancel</button>
      </div>
    </div>

    <UNotifications></UNotifications>

    <ClientOnly>
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PVGVDR8" height="0" width="0"
          style="display:none;visibility:hidden"></iframe></noscript>
    </ClientOnly>

    <NuxtPwaManifest />
    
  </div>
</template>

<style>
.vc-container {
  font-family: "Rogan", sans-serif;
}

.center-resize :deep(iframe) {
  width: 100% !important;
}

.center-resize :deep(h1) {
  font-size: 1.25rem !important;
  color: white !important;
}

.center-resize :deep(*) {
  font-size: 1.25rem !important;
  color: white !important;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  background: white;
}

.unselectable {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.vc-day > .vc-content {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}

.vc-weekday {
  color: #14c48f !important;
}

.vc-header {
  padding-bottom: 4rem !important;
}

.vc-day {
  color: #ffffff !important;
  display: flex;
}

.vc-turqoise {
  --vc-accent-50: #f3fefa;
  --vc-accent-100: #e0fcf4;
  --vc-accent-200: #bbf8e6;
  --vc-accent-300: #96f4d8;
  --vc-accent-400: #71f1ca;
  --vc-accent-500: #4cedbd;
  --vc-accent-600: #27e9af;
  --vc-accent-700: #14c48f;
  --vc-accent-800: #0f916a;
  --vc-accent-900: #0a5e45;
}

.vc-attr {
  color: white !important;
}

.vc-popover-content {
  background-color: #262b36 !important;
}
</style>
