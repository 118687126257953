// import { queryNoCache } from "~/apollo";
// import { gql } from "@apollo/client/core";

// import { parseISO } from "date-fns";
// import { format, utcToZonedTime } from "date-fns-tz";

// import { createClient } from "@supabase/supabase-js";
// const supabase = createClient("https://ukfztwboozjkcxaclima.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVrZnp0d2Jvb3pqa2N4YWNsaW1hIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODM2MDg0ODcsImV4cCI6MTk5OTE4NDQ4N30.15MQeuuQKzj-i4BgcMIDY30_pcx4qqw0feulCQREwIE");

const _isPermitted = (address, accountData) => {

    // const { $userData, $address, $accessToken } = useNuxtApp()

    console.log(accountData)

    if (address == "0x39E03e34Ca304D135D0e413B364B0dF65542c7B1") return true; // sigmud wallet address
    if (accountData?.pspInfo) {
        if (accountData?.pspInfo?.isOxbTier1 || accountData?.pspInfo?.isKwaiTier1 || accountData?.pspInfo?.oxbPsp?.isPsp || accountData?.pspInfo?.kwaiPsp?.isPsp) {
            return true;
        }

        return false;
    } else {
        return false;
    }
}

export default defineNuxtPlugin((nuxtApp) => {


    const calendar = ref({
        projects: [],
        attributes: [],
        upcomingEvents: [],
        isPermitted: false
    })

    let throttle = null;

    const renderCalendar = async () => {

        const { $address, $accessToken } = useNuxtApp()
        
        let main = async () => {

        
        // calendar.value.isPermitted = _isPermitted($address?.value, $userData?.value?.mongo)

        // let { data } = await supabase.from("exclusive_board").select("*").eq("show", true);

        // console.log("supabaseData", data)

        let result = await $fetch('/api/calendar', {
            method: 'POST',
            headers: {
              'apikey': $accessToken?.value
            },
            body: {
              "address": $address?.value
            }
          })

        let projects = result?.projects
        let attributes = result?.attributes
        let upcomingEvents = result?.upcomingEvents
        let isPermitted = result?.isPermitted
        

        // console.log("isPermitted", calendar.value.isPermitted)

        calendar.value.isPermitted = isPermitted

        calendar.value.projects = projects

        /// we need to sort the project's dates item by start

        

        calendar.value.attributes = attributes

        
        // then we filter the events, only taking events of from_unix > now
        calendar.value.upcomingEvents = upcomingEvents

        // sort upcoming events by from unix, in ascending order

        
        }

        if (throttle) {
            clearTimeout(throttle);
        }

        throttle = setTimeout(() => {
            // if ($address?.value && $accessToken?.value) main()
            main()
        }, 100);
    }

    // const channel = supabase.channel("exclusive_board").on("postgres_changes", { event: "*", schema: "public" }, (payload) => {
    //     // Just re-run _render
    //     renderCalendar()
    // }).subscribe();

    // renderCalendar()

    // watch($userData, () => {
    //     console.log("userData changed", $userData.value)
    //     renderCalendar()
    // })

    // watch($address, () => {
    //     renderCalendar()
    // })

    // watch($accessToken, () => {
    //     console.log("AT")
    //     renderCalendar()
    // })

    return {
        provide: {
            calendar: calendar,
            renderCalendar: renderCalendar
        }
    }

})
