import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@2.79.1_sass@1.77.8_typescript@5.5.4_vite@5.4.0/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/buildhome/repo/app/router.options.js";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}